import { createSlice, createAction } from '@reduxjs/toolkit'
import { commonState } from './commonSliceTyping'

const initialState: commonState = {
    isMenuOpened: false,
    isPageLoading: true,
    isAppContainerFixed: false,
    isLocationTracking: false,
    isChangingPassword: false,
    isPremiumDialogOpen: false,
    isLoaderDisabled: false,
    scrolledPagesAmountAndPosition: {
        pages: 1,
        scrollPosition: 0,
        sourcePage: undefined
    },
    forceDisableMenu: false,
    isUserActive: false,
    isPageScrolled: false,
    isLeavingPage: false,
    swiperTopPosition: undefined,
    scrollToTopBottom: undefined,
    backButton: {
        urlList: [],
        avatarUrl: null,
        isNecessaryToShowBackButton: false,
        isNecessaryToGetAvatarFromContribution: true,
        isNecessaryToShowAvatar: false,
        isNecessaryToDeleteAvatar: false
    },
    pm_common: {
        userIdVideoChatRequest: null,
        call_id: null,
        state: null,
        ended_reason: null,
        incoming: null,
        sessionHiddenCallers: [],
        isFullScreenOnMobile: false
    },
    pageToReturnFromPayment: null
}

const incomingCall = createAction('PM_VIDEO_CALL_INFO')
const incomingCallError = createAction('PM_ACTION_VIDEO_CALL_START_ERROR')
const sendMessage = createAction('PM_SEND_MESSAGE')

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        switchMenu(state) {
            state.isMenuOpened = !state.isMenuOpened
        },
        closeMenu(state) {
            state.isMenuOpened = false
        },
        openMenu(state) {
            state.isMenuOpened = true
        },
        switchPageLoading(state) {
            state.isPageLoading = !state.isPageLoading
        },
        pageLoaded(state) {
            state.isPageLoading = false
        },
        pageStartLoading(state) {
            state.isPageLoading = true
        },
        fixAppContainer(state) {
            state.isAppContainerFixed = true
        },
        unfixAppContainer(state) {
            state.isAppContainerFixed = false
        },
        switchLocationTracking(state) {
            state.isLocationTracking = !state.isLocationTracking
        },
        changeDefaultPageScrolledPagesAmountAndPosition(state) {
            state.scrolledPagesAmountAndPosition = {
                pages: 1,
                scrollPosition: 0,
                sourcePage: undefined
            }
        },
        changeAmountOfPages(state, action) {
            state.scrolledPagesAmountAndPosition = {
                ...state.scrolledPagesAmountAndPosition,
                pages: action.payload.page
            }
        },
        changeScrolledPosition(state, action) {
            state.scrolledPagesAmountAndPosition = {
                ...state.scrolledPagesAmountAndPosition,
                scrollPosition: action.payload.scrollPosition
            }
        },
        changeSourcePage(state, action) {
            state.scrolledPagesAmountAndPosition = {
                ...state.scrolledPagesAmountAndPosition,
                sourcePage: action.payload.source
            }
        },
        switchUserOnActive(state) {
            state.isUserActive = true
        },
        switchUserOnNotActive(state) {
            state.isUserActive = false
        },
        disableMenu(state) {
            state.forceDisableMenu = true
        },
        enableMenu(state) {
            state.forceDisableMenu = false
        },
        switchPageOnScrolled(state) {
            state.isPageScrolled = true
        },
        switchPageOnNotScrolled(state) {
            state.isPageScrolled = false
        },
        setIsLeavingPage(state, action) {
            state.isLeavingPage = action.payload
        },
        setSwiperTopPosition(state, action) {
            state.swiperTopPosition = action.payload
        },
        deleteSwiperTopPosition(state) {
            state.swiperTopPosition = undefined
        },
        scrollToTop(state) {
            state.scrollToTopBottom = 'top'
        },
        scrollBottom(state) {
            state.scrollToTopBottom = 'bottom'
        },
        pageScrolled(state) {
            state.scrollToTopBottom = undefined
        },
        saveAvatarUrl(state, action) {
            state.backButton.avatarUrl = action.payload
        },
        clearAvatarUrl(state) {
            state.backButton.avatarUrl = null
        },
        addUrlToArray(state, action) {
            state.backButton.urlList.push(action.payload)
        },
        initOfSavingPath(state, action) {
            state.backButton.urlList = [action.payload]
        },
        clearUrlList(state) {
            state.backButton.urlList = []
        },
        deleteLastUrl(state) {
            state.backButton.urlList.pop()
        },
        switchIsNecessaryToShowBackButtonOnTrue(state) {
            state.backButton.isNecessaryToShowBackButton = true
        },
        switchIsNecessaryToShowBackButtonOnFalse(state) {
            state.backButton.isNecessaryToShowBackButton = false
        },
        switchIsNecessaryToShowAvatarOnTrue(state) {
            state.backButton.isNecessaryToShowAvatar = true
        },
        switchIsNecessaryToShowAvatarOnFalse(state) {
            state.backButton.isNecessaryToShowAvatar = false
        },
        switchIsNecessaryToDeleteAvatarOnTrue(state) {
            state.backButton.isNecessaryToDeleteAvatar = true
        },
        switchIsNecessaryToDeleteAvatarOnFalse(state) {
            state.backButton.isNecessaryToDeleteAvatar = false
        },
        switchIsNecessaryToGetAvatarFromContributionOnTrue(state) {
            state.backButton.isNecessaryToGetAvatarFromContribution = true
        },
        switchIsNecessaryToGetAvatarFromContributionOnFalse(state) {
            state.backButton.isNecessaryToGetAvatarFromContribution = false
        },
        switchChangingPasswordOnTrue(state) {
            state.isChangingPassword = true
        },
        switchChangingPasswordOnFalse(state) {
            state.isChangingPassword = false
        },
        cleanPm(state) {
            state.pm_common = null
        },
        addHiddenCaller(state, action) {
            state.pm_common.sessionHiddenCallers.push(action.payload.id)
        },
        openPremiumDialog(state) {
            state.isPremiumDialogOpen = true
        },
        closePremiumDialog(state) {
            state.isPremiumDialogOpen = false
        },
        switchOnFullScreenMobile(state) {
            state.pm_common.isFullScreenOnMobile = true
        },
        exitFullScreenMobile(state) {
            state.pm_common.isFullScreenOnMobile = false
        },
        deletePm_commonEndedReason(state) {
            state.pm_common.ended_reason = null
        },
        setPageToReturnFromPayment(state, action) {
            state.pageToReturnFromPayment = action.payload
        },
        disableLoader(state) {
            state.isLoaderDisabled = true
        },
        enableLoader(state) {
            state.isLoaderDisabled = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(incomingCall, (state, action: any) => {
                if (action.call.state !== 'deleted') {
                    state.pm_common.userIdVideoChatRequest = action.conversation_uid
                    state.pm_common.call_id = action.call.call_id
                    state.pm_common.state = action.call.state
                    state.pm_common.incoming = action.call.incoming
                }
                if (action.call.state === 'ended') state.pm_common.ended_reason = action.call.ended_reason
            })
            .addCase(incomingCallError, (state, action: any) => {
                state.pm_common.state = 'ended'
                state.pm_common.ended_reason = action.reason
                state.pm_common.userIdVideoChatRequest = action.conversation_uid
            })
            .addCase(sendMessage, (state, action: any) => {
                console.log(action.conversation_uid)
                localStorage.setItem(`chat_${action.conversation_uid}`, '')
            })
    }
})

export default commonSlice.reducer
export const {
    switchMenu,
    closeMenu,
    openMenu,
    switchPageLoading,
    pageStartLoading,
    pageLoaded,
    fixAppContainer,
    unfixAppContainer,
    switchLocationTracking,
    changeAmountOfPages,
    changeScrolledPosition,
    changeSourcePage,
    switchUserOnActive,
    switchUserOnNotActive,
    switchPageOnNotScrolled,
    switchPageOnScrolled,
    changeDefaultPageScrolledPagesAmountAndPosition,
    setIsLeavingPage,
    setSwiperTopPosition,
    deleteSwiperTopPosition,
    scrollToTop,
    scrollBottom,
    pageScrolled,
    addUrlToArray,
    initOfSavingPath,
    clearUrlList,
    deleteLastUrl,
    saveAvatarUrl,
    clearAvatarUrl,
    switchIsNecessaryToShowAvatarOnFalse,
    switchIsNecessaryToShowAvatarOnTrue,
    switchIsNecessaryToShowBackButtonOnTrue,
    switchIsNecessaryToShowBackButtonOnFalse,
    switchIsNecessaryToDeleteAvatarOnTrue,
    switchIsNecessaryToDeleteAvatarOnFalse,
    switchIsNecessaryToGetAvatarFromContributionOnTrue,
    switchIsNecessaryToGetAvatarFromContributionOnFalse,
    switchChangingPasswordOnTrue,
    switchChangingPasswordOnFalse,
    cleanPm,
    addHiddenCaller,
    openPremiumDialog,
    closePremiumDialog,
    switchOnFullScreenMobile,
    exitFullScreenMobile,
    deletePm_commonEndedReason,
    setPageToReturnFromPayment,
    disableMenu,
    enableMenu,
    disableLoader,
    enableLoader
} = commonSlice.actions
